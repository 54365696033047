﻿import { global, isEmpty, isUnDefined, filterTableRows, convertString2Date, formatDate } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopPopover } from "../Components/saop-popover";
import { SaopRegView } from "./saop-regUra-view";
import { SaopRegDetailsTableView } from "./saop-regUra-details-table-view";
import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebDataTable} from "../Components/saop-grid";
import { SaopRegUraDogodekView } from "../REG/saop-regUraDogodek-view";
import { SaopRegUraDogodekDeleteView } from "../REG/saop-regUraDogodekDelete-view";
import { SaopWebGridUnitOrgFilter } from "../Components/saop-grid-filter-UnitOrg";

class DataAttributes {
    sifraZaposleni: string;
    leto: number;
    mesec: number;
}

class ObdobjefilterValues {
    leto: number;
    mesec: number;
    mesecNaziv: string;
}

export class SaopRegAttendanceAllEmployeesView {
    private _libName: string;
    private _refreshingAllEmployeesTable: boolean;
    private _tableId: string;
    private _inputHiddenDataId: string;
    private _searchAllFieldsValueId: string;
    private _obdobjeId: string;
    private _calendarIconId: string;
    private _obdobjeFilterLetoId: string;
    private _obdobjeFilterMesecId: string;
    private _obdobjeCurrentBtnId: string
    private _rowDetailsPrefixId: string;
    private _rowDetailsDivPrefixId: string;
    private _allEmployeesTableDivId: string; 
    private _allEmployeesTableRowsId: string;
    private _markColumnCss: string;
    private _selectOrgFilter: SaopWebGridUnitOrgFilter;
    private _saopPopover: SaopPopover;
    private _regUraView: SaopRegView;
    private saopRegDetailsTableView: SaopRegDetailsTableView;
    private _lastDataValues:DataAttributes;
    private _regUraDogodek:SaopRegUraDogodekView;    
    private _regUraDogodekDeleteView:SaopRegUraDogodekDeleteView;  
    //
    public webGrid1:SaopWebGrid;
    //

    constructor(libName: string = "") {
        this._libName = libName;
        this._refreshingAllEmployeesTable = false;
        this._tableId = "#attendanceAllEmployeesTable";
        this._inputHiddenDataId = "#allEmployeesTableHiddenData";
        this._searchAllFieldsValueId = "#SearchValue";
        this._obdobjeId = "#Obdobje";
        this._calendarIconId = "#calendarIcon";
        this._obdobjeFilterLetoId = "#letoObdobje";
        this._obdobjeFilterMesecId = "#MesecString";
        this._obdobjeCurrentBtnId = "#currentMonthBtnFilter";
        this._rowDetailsPrefixId = "#detailRow";
        this._rowDetailsDivPrefixId = "#detailRowDiv";
        this._allEmployeesTableDivId = "#attendanceAllEmployeesTableDiv";
        this._allEmployeesTableRowsId = "#attendanceAllEmployeesTableRows";
        this._markColumnCss = "saop-column-mark";

        this._selectOrgFilter = new SaopWebGridUnitOrgFilter(libName, "");
        this._selectOrgFilter.filterZaposleniTableRows = this.filterZaposleniTableRows.bind(this);

        this._saopPopover = new SaopPopover();
        this._saopPopover.afterPopoverShow = this.afterPopoverShow.bind(this);

        this._regUraView = new SaopRegView(libName + "._regUraView");
        this.saopRegDetailsTableView = new SaopRegDetailsTableView(libName + ".saopRegDetailsTableView", "#reg-grid");
        //
        this._regUraView.afterRefreshData = this.afterRefreshTodayData.bind(this);
        this._regUraView.onCloseView = this.onCloseRegUraDanView.bind(this);       
        //
        this._regUraDogodek = new SaopRegUraDogodekView("#regDogodekView0",libName + "._regUraDogodek");
        this._regUraDogodek.refreshData = this.afterAddEventsSuccess.bind(this);
        // 
        this._regUraDogodekDeleteView = new SaopRegUraDogodekDeleteView("#regDogodekView0",libName + "._regUraDogodekDeleteView");
        this._regUraDogodekDeleteView.afterDeleteSuccess = this.afterDeleteEventsSuccess.bind(this);
        this._regUraDogodekDeleteView.afterValidationError = this.onDeleteValidationError.bind(this);
        
    }


    onInitView():void{
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.onInitView");
        this.refreshAllData();
    }

    initWebGrid1(){
        let wgId = "#attendanceAllEmployeesTable";
        let captionToolbar = $(wgId).attr("caption-toolbar");
        //let captionAddButton = $(wgId).attr("caption-addButton"); 
        //
        this.webGrid1 = new SaopWebGrid(this._libName+".webGrid1");
        let gridProperties: SaopWebGridProperties;
        let gridToolbar:SaopWebGridToolbar;
        //let toolbarButtons:SaopWebGridToolbarButton[] = [];
        let dataTable:SaopWebDataTable;
        //
        //toolbarButtons.push({id:"addTo",caption:captionAddButton,onClickEvent:this.libName+".addRecord();"});        
        //gridToolbar = {id:"saopWebGridTB1",caption:captionToolbar,buttons:toolbarButtons};
       
        dataTable = {enabled:true};
    
        gridProperties = {
            gridId:wgId
            //,toolbar:gridToolbar
            ,dataTable:dataTable
          };        
        //
        this.webGrid1.init(gridProperties);   
          
      }

    refreshAllData(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.refreshAllData");

        this.refreshAllEmployeesData();
    }  

    refreshAllEmployeesData(params: any = null): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.refreshAllEmployeesData");
       
        let _this = this;
        let saopServicesCore = new SaopServicesCore();

        saopServicesCore.afterDone = function (response) {
            $("#attendanceAllEmployeesForm").html(response);
            _this.initFilterControls();
            _this.bindGridEvents();
            _this.setEventBindings();
            // nastavimo prazno zabelo za prikaz mesecnih podatkov
            _this.getEmptyEmployeeMonthData();
        };
        saopServicesCore.ExecuteAction({
            action: "REGAttendanceAllEmployees/GetAllEmployeesForm"
            , data: params
            , type: "GET"
            , spinnerDivId: "#attendanceAllEmployeesLoading"
        });    
    }

    initFilterControls(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.initFilterControls");
        let _this = this;

        //
        this.buttonsBinding();
        //
        this.setEventBindings();
        //
        this._selectOrgFilter.initFilterControls();

        $(this._searchAllFieldsValueId).keyup(function () {
            let oTable = _this.webGrid1.dataTable;
            oTable.search($(this).val().toString()).draw() ;           
            //$(_this.webGrid1.webGridId).dataTable().search($(this).val()).draw() ;
        });

        this._saopPopover.bindPopoverEvents();

        this.initObdobjeControl()
    }

    filterAllFields(): void {
        let filterVal = String($(this._searchAllFieldsValueId).val());
        filterTableRows(filterVal, this._tableId);
    }

    checkAndOnlyShowHideDetailsTable(masterRowId: string, isShowDemanded: boolean): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.checkAndOnlyShowHideDetailsTable");

        let selectedRow = $("#" + masterRowId);
        let actionDetailsColumn = selectedRow.find("td").eq(1);
        let dataColumn = selectedRow.find("td").eq(0);
        let dataValues = this.getDataValues(dataColumn, $(this._obdobjeId));

        // ce je definirano, da so details vidni, prikazi details tabelo
        let iconElement = actionDetailsColumn.find("span");

        if (this.isDetailsSubTableShowed(iconElement)) {
            // prikazi/skrij details tabelo
            let rowDetailsId = this._rowDetailsPrefixId + dataValues.sifraZaposleni;
            let rowDetailsIdElement = $(rowDetailsId);
            let addClass = isShowDemanded ? "saop-table-row-showed" : "saop-table-row-hidden";
            let removeClass = isShowDemanded ? "saop-table-row-hidden" : "saop-table-row-showed";
            rowDetailsIdElement.addClass(addClass);
            rowDetailsIdElement.removeClass(removeClass);
        }
    }

    initObdobjeControl(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.initObdobjeControl");

        this.setObdobjeText(this._obdobjeId)
    }

    setObdobjeText(id: string): void {
        let obdobjeElement = $(this._obdobjeId);

        let mesecNaziv = obdobjeElement.attr("data-mesec-naziv");
        let leto = obdobjeElement.attr("data-leto");

        obdobjeElement.val(mesecNaziv + " " + leto);
    }

    getObdobjeDataAttributes(id: string): ObdobjefilterValues {
        let obdobjeElement = $(this._obdobjeId);

        let dataAttributes = new ObdobjefilterValues();
        dataAttributes.leto = parseInt(obdobjeElement.attr("data-leto"));
        dataAttributes.mesec = parseInt(obdobjeElement.attr("data-mesec"));
        dataAttributes.mesecNaziv = obdobjeElement.attr("data-mesec-naziv");

        return dataAttributes;
    }
    
    setObdobjeDataAttributes(id: string, values: ObdobjefilterValues): void {
        let obdobjeElement = $(id);

        if (values.leto != null && values.mesec != null) {
            obdobjeElement.attr("data-leto", values.leto);
            obdobjeElement.attr("data-mesec", values.mesec);
            obdobjeElement.attr("data-mesec-naziv", values.mesecNaziv);

            this.setObdobjeText(this._obdobjeId);
        } 
    }

    bindGridEvents(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.bindGridEvents");
        let _this = this;

        // prikaz dogodkov zaposlenega po posameznih dnevih
        $("body").off("click", this._tableId + " tr.saop-table-row-master td:not(.td-action-details)");
        $("body").on("click", this._tableId + " tr.saop-table-row-master td:not(.td-action-details)", function () {
            let selectedRow = $(this).closest("tr");
            let dataColumn = $(this).closest("tr").find("td").eq(0);
            let dataValues = _this.getDataValues(dataColumn, $(_this._obdobjeId));
            _this._lastDataValues = dataValues;
            _this.allEmployeesTableMarkRow(selectedRow, dataValues);
            _this.refreshEmployeeMonthData(dataValues);
        });

        // prikaz dogodkov zaposlenega po vrsti dogodkov (sum ure)
        $("body").off("click", this._tableId + " tr.saop-table-row-master td.td-action-details");
        $("body").on("click", this._tableId + " tr.saop-table-row-master td.td-action-details", function () {
            let selectedRow = $(this).closest("tr");
            let actionDetailsColumn = selectedRow.find("td").eq(2);
            let dataColumn = $(this).closest("tr").find("td").eq(0);
            let dataValues = _this.getDataValues(dataColumn, $(_this._obdobjeId));
            _this.showHideDetailsSubTable(selectedRow, actionDetailsColumn, dataValues);
        });
        //
        this.initWebGrid1();
        //
        this.bindDropDownMenuEvents();           
    }

    public bindDropDownMenuEvents(): void {
        let _this = this;
        //
        $(".attendanceAllEmployeesFormCheckIn").off("click");
        $(".attendanceAllEmployeesFormCheckIn").on('click', function(row){
            global.ConsoleLogDegug("attendanceAllEmployeesFormCheckIn.click");
            _this.attendanceAllEmployeesFormCheckInOut(row,true);          
        });     
        $(".attendanceAllEmployeesFormCheckOut").off("click");
        $(".attendanceAllEmployeesFormCheckOut").on('click', function(row){
            global.ConsoleLogDegug("attendanceAllEmployeesFormCheckOut.click");
            _this.attendanceAllEmployeesFormCheckInOut(row,false);
        });                  
        //         
    }

    getDataValues(dataColumn: JQuery<HTMLTableCellElement>, dataInput: JQuery<HTMLInputElement>): DataAttributes {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.getDataValues");

        let dataValues = new DataAttributes();
        if (dataColumn != null && dataColumn.length > 0){
            dataValues.sifraZaposleni = dataColumn.attr("data-sifrazaposleni");
        }
        
        dataValues.leto = parseInt(dataInput.attr("data-leto"));
        dataValues.mesec = parseInt(dataInput.attr("data-mesec"));

        return dataValues;
    }

    // oznacimo, na katero vrstico je uporabnik kliknil
    allEmployeesTableMarkRow(selectedRow: JQuery<HTMLTableRowElement>, dataValues: DataAttributes): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.allEmployeesTableMarkRow");

        // odznacimo vse vrstice
        this.allEmployeesTableUnMarkRows();

        let markColumn = selectedRow.find("td.saop-td-mark-row");
        markColumn.addClass(this._markColumnCss);

        // oznacimo se details row markcolumn
        let rowDetailsElement = $(this._rowDetailsPrefixId + dataValues.sifraZaposleni);
        let detailsMarkColumn = rowDetailsElement.find("td.saop-td-mark-row");
        detailsMarkColumn.addClass(this._markColumnCss);
    }

    allEmployeesTableUnMarkRows(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.allEmployeesTableUnMarkRows");

        let _this = this;

        $(this._tableId + " > tbody > tr").each(
            function (index: any, tr: any) {
                let markColumn = $(tr).find("td").eq(1);
                markColumn.removeClass(_this._markColumnCss);
            }
        );
    }

    // osvezimo mesecne podatke za posameznega zaposlenega
    // ce je parameter null, se prikaze "Izberite zaposlenega za prikaz dogodkov" 
    refreshEmployeeMonthData(dataValues: DataAttributes): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.refreshEmployeeMonthData");
        global.ConsoleLogDegug(dataValues);

        if (dataValues.sifraZaposleni == null) {
            return;
        }

        let _this = this;
        let params = { leto: dataValues.leto, mesec: dataValues.mesec, sifraZaposleni: dataValues.sifraZaposleni };
        let saopServicesCore = new SaopServicesCore();

        //
        let employeeName = $("#attendanceAllEmployeesTableRows").find("[data-sifra-zaposleni='" + dataValues.sifraZaposleni + "']").find("#employeeName").html();
        $("#attendanceAllEmployeesRightSideDiv").find("#employeeName").html(employeeName);
        //

        saopServicesCore.afterDone = function (response) {
            $("#reg-grid").html(response);
            _this.refreshEmployeeMonthTableHtml();
        };
        saopServicesCore.ExecuteAction({
            action: "REGMonthEvents/GetRegUraTableView2"
            , data: params
            , type: "GET"
            , spinnerDivId: "#saopLoadingEmployeeMonthGrid"
        });
    }

    // vrne prazno tabelo mesecne podatke za zaposlenega
    getEmptyEmployeeMonthData(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.getEmptyEmployeeMonthData");

        let _this = this;
        let saopServicesCore = new SaopServicesCore();

        $("#attendanceAllEmployeesRightSideDiv").find("#employeeName").html("");

        saopServicesCore.afterDone = function (response) {
            $("#reg-grid").html(response);
            _this.refreshEmployeeMonthTableHtml();
            //_this.bindDropDownMenuEvents();
        };
        saopServicesCore.ExecuteAction({
            action: "REGMonthEvents/GetEmptyRegUraTableView"
            , type: "GET"
            , spinnerDivId: "#saopLoadingEmployeeMonthGrid"
        });
    }

    // osvezimo table html elemente
    refreshEmployeeMonthTableHtml(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.refreshEmployeeMonthTableHtml");
        // refresh month employee data brez bind events
        let _btnAddEvents = $("#btnAddEvents");
        if (_btnAddEvents.length > 0) {
            this._regUraView.refreshTableData(true);
        } else {
            this._regUraView.refreshTableData(false);
        }

        // dodamo bind event za details tabelo
        this.saopRegDetailsTableView.bindGridEvents();

        this.bindDropDownMenuEvents();
    }

    isDetailsSubTableShowed(iconElement: JQuery<HTMLSpanElement>): boolean {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.isDetailsSubTableShowed");

        return iconElement.hasClass("saop-img-down");
    }

    showHideDetailsSubTable(selectedRow: JQuery<HTMLTableRowElement>, actionDetailsColumn: JQuery<HTMLTableCellElement>, dataValues: DataAttributes): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.showHideDetailsSubTable");

        let iconElement = actionDetailsColumn.find("span");

        let rowDetailsId = this._rowDetailsPrefixId + dataValues.sifraZaposleni;
        let rowDetailsIdElement = $(rowDetailsId);

        if (!this.isDetailsSubTableShowed(iconElement)) {
            // show details subtable
            // preverim, če tabela ze obstaja
            let detailRowDivId = this._rowDetailsDivPrefixId + dataValues.sifraZaposleni;
            let detailRowTableElement = $(detailRowDivId + " table");
            if (detailRowTableElement.length == 0) {
                // tabele ni, gremo na server po podatke za prikaz
                this.getDetailsSubTable(detailRowDivId, dataValues.leto, dataValues.mesec, dataValues.sifraZaposleni);
            }
        }

        // prikazi/skrij bottom border
        selectedRow.toggleClass("saop-table-row-border-bottom-none")

        // prikazi/skrij details tabelo
        rowDetailsIdElement.toggleClass("saop-table-row-hidden");
        rowDetailsIdElement.toggleClass("saop-table-row-showed");

        // sprememba ikone
        iconElement.toggleClass("saop-img-right");
        iconElement.toggleClass("saop-img-down");
    }

    getDetailsSubTable(detailRowDivId: string, leto: number, mesec: number, sifraZaposleni: string): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.getDetailsSubTable");

        let saopServicesCore = new SaopServicesCore();
        let params = { leto: leto, mesec: mesec, sifraZaposleni: sifraZaposleni };

        saopServicesCore.afterDone = function (response) {
            $(detailRowDivId).html(response);
        };
        saopServicesCore.ExecuteAction({
            action: "REGAttendanceAllEmployees/GetEmployeeEventsForm"
            , data: params
            , type: "GET"
            , spinnerDivId: "#attendanceAllEmployeesLoading"
        });    
    }

    obdobjeClick(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.obdobjeClick");
        $(this._calendarIconId).trigger("click");
    }

    afterPopoverShow(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.afterPopoverShow");

        let obdobjeData = this.getObdobjeDataAttributes(this._obdobjeId);

        // osvezimo vrednosti filtrov iz data attributes
        this.setObdobjeFilterValues(obdobjeData);

        // prikazi okno
        var filterBody = $("div.popover.show").find("#popOverObdobjeBody");
        filterBody.show();
    }

    // klik na gumb trenutni v popover filtru za obdobje
    obdobjeCurrentMonth(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.obdobjeCurrentMonth");

        let currentMonthFilterValues = new ObdobjefilterValues();

        currentMonthFilterValues.leto = new Date().getFullYear();
        currentMonthFilterValues.mesec = new Date().getMonth() + 1;
        currentMonthFilterValues.mesecNaziv = this.getMonthName(currentMonthFilterValues.mesec);

        // zapisemo vrednost v filter leto
        this.setObdobjeFilterValues(currentMonthFilterValues);

        // osvezimo obdobje element z novimi podatki
        this.setObdobjeDataAttributes(this._obdobjeId, currentMonthFilterValues);

        // osvezimo tabelo podatkov vseh zaposlenih
        this.refreshAllEmployeesTable(currentMonthFilterValues.leto, currentMonthFilterValues.mesec);
    }

    // na popover filtru za obdobje pomik za en mesec naprej, nazaj
    obdobjefiltersMonthStepChange(step: number): void {
        if (this._refreshingAllEmployeesTable == true) {
            // poteka postopek osvezevanja pdoatkov, novi zahtevek ni dovoljen 
            return;
        }

        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.obdobjefiltersMonthStepChange");

        // preberemo vrednosti leto in mesec
        let filterValues = this.getObdobjefilterValues();

        let newFilterValues = new ObdobjefilterValues();
        newFilterValues.mesec = filterValues.mesec + step;
        newFilterValues.leto = filterValues.leto;
        if (newFilterValues.mesec == 13) {
            // za december je januar
            newFilterValues.mesec = 1;
            newFilterValues.leto = newFilterValues.leto + 1;
        }
        else if (newFilterValues.mesec == 0) {
            // pred januarjem je december
            newFilterValues.mesec = 12;
            newFilterValues.leto = newFilterValues.leto - 1;
        }

        // poiscemo se naziv meseca
        newFilterValues.mesecNaziv = this.getMonthName(newFilterValues.mesec);

        // zapisemo novo vrednost v filter leto
        this.setObdobjeFilterValues(newFilterValues);

        // osvezimo obdobje element z novimi podatki
        this.setObdobjeDataAttributes(this._obdobjeId, newFilterValues);

        // osvezimo tabelo podatkov vseh zaposlenih
        this.refreshAllEmployeesTable(newFilterValues.leto, newFilterValues.mesec);
    }

    getMonthName(month: number): string {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.getMonthName");

        let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
        if (mesecElement.length) {
            return $(mesecElement).find(" option[value = '" + month + "']").text();
        }

        return "";
    }

    enableDisableObdobjeFilters(disable: boolean): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.enableDisableObdobjeFilters");

        // leto
        let letoElement = $("div.popover.show").find(this._obdobjeFilterLetoId);
        if (letoElement.length) {
            letoElement.prop("disabled", disable);
        }

        // mesec
        let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
        if (mesecElement.length) {
            mesecElement.prop("disabled", disable);
        }  

        // gumb trenutni
        let _obdobjeCurrentBtnElement = $("div.popover.show").find(this._obdobjeCurrentBtnId);
        if (_obdobjeCurrentBtnElement.length) {
            _obdobjeCurrentBtnElement.prop("disabled", disable);
        } 
    }

    setObdobjeFilterValues(filterValues: ObdobjefilterValues): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.setObdobjeFilterValues");

        let oldfilterValues = this.getObdobjefilterValues();

        // leto
        if (oldfilterValues.leto != filterValues.leto) {
            let letoElement = $("div.popover.show").find(this._obdobjeFilterLetoId);
            if (letoElement.length) {
                letoElement.val(filterValues.leto);
            }
        }

        // mesec
        if (oldfilterValues.mesec != filterValues.mesec) {
            let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
            if (mesecElement.length) {
                mesecElement.val(filterValues.mesec.toString());
            }  
        } 
    }

    // vrednosti v filtru leto, mesec so se spremenile
    obdobjefiltersChanged(): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.obdobjefiltersChanged");

        // preberemo vrednosti leto in mesec
        let filterValues = this.getObdobjefilterValues();

        // osvezimo obdobje element z novimi podatki
        this.setObdobjeDataAttributes(this._obdobjeId, filterValues);

        this.refreshAllEmployeesTable(filterValues.leto, filterValues.mesec);
    }

    getObdobjefilterValues(): ObdobjefilterValues {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.getObdobjefilterValues");

        let filterValues = new ObdobjefilterValues();
        filterValues.leto = null;
        filterValues.mesec = null;
        filterValues.mesecNaziv = "";

        // dolocimo leto
        let letoElement = $("div.popover.show").find(this._obdobjeFilterLetoId);
        if (letoElement.length) {
            let letoStr = String(letoElement.val());
            if (letoStr.length == 4 || letoStr.length == 0) {
                if (letoStr.length == 4) {
                    filterValues.leto = +letoStr;
                }
            }
        }
        
        // dolocimo mesec
        let mesecElement = $("div.popover.show").find(this._obdobjeFilterMesecId);
        if (mesecElement.length) {
            let selMesecNaziv = $(mesecElement).find(":selected").text();
            let selMesec = $(mesecElement).find(":selected").val();

            if (!isNaN(+selMesec)) {
                filterValues.mesec = +selMesec
                filterValues.mesecNaziv = selMesecNaziv
            }
        }

        return filterValues;
    }

    refreshAllEmployeesTable(leto: number, mesec: number): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.refreshAllEmployeesTable");

        let _this = this;
        let saopServicesCore = new SaopServicesCore();
        let params = { leto: leto, mesec: mesec };
        this._refreshingAllEmployeesTable = true;

        this.enableDisableObdobjeFilters(this._refreshingAllEmployeesTable);

        saopServicesCore.afterDone = function (response) {
            _this.webGrid1.replaceGridRows(response);

            //$(_this._tableId).html(response);
           
            _this._refreshingAllEmployeesTable = false;
            _this.enableDisableObdobjeFilters(_this._refreshingAllEmployeesTable);

            // izvedemo se filter vrstic tabele glede oznacenih zaposlenih
            let izbraniZaposleni = <string[]>(_this._selectOrgFilter._selectZaposleni.selectedCB.val());
            _this._selectOrgFilter._selectZaposleni.setSelectValues(izbraniZaposleni);

            // nastavimo prazno zabelo za prikaz mesecnih podatkov
            _this.getEmptyEmployeeMonthData();
        };
        saopServicesCore.ExecuteAction({
            action: "REGAttendanceAllEmployees/GetAllEmployeesTable"
            , data: params
            , type: "GET"
            , spinnerDivId: "#attendanceAllEmployeesLoading"
        });    

    }

    refreshEmployeeRow(leto: number, mesec: number, sifraZaposleni: string = ""): void {
        global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.refreshEmployeeTable:"+sifraZaposleni);

        let _this = this;
        let saopServicesCore = new SaopServicesCore();
        let params = { leto: leto, mesec: mesec, sifraZaposleni: sifraZaposleni };

        saopServicesCore.afterDone = function (response) {
            _this.refreshEmployeeRowHtml(response,sifraZaposleni); 
        };
        saopServicesCore.ExecuteAction({
            action: "REGAttendanceAllEmployees/GetAllEmployeesTable"
            , data: params
            , type: "GET"
            , spinnerDivId: "#attendanceAllEmployeesLoading"
        });    
    }

    public refreshEmployeeRowHtml(htmlRespone:string,sifraZaposleni:string): void {
        //var source_dom_nodes = $($.parseHTML('<div>'+htmlRespone+'</div>'));
        //
        let responeNode = $(htmlRespone);

        //let sourceRow = responeNode[0].outerHTML;
        let sourceRow = $(responeNode[2].outerHTML);
        //let sourceDetailsRow = responeNode[2].outerHTML;

        let destinationRow = $(this._allEmployeesTableRowsId + " > [data-sifra-zaposleni='" + sifraZaposleni + "']");
        // destinationDetailsRow = $(this._allEmployeesTableRowsId+" > #detailRow"+sifraZaposleni);"[data-sifra-zaposleni='" + dataValues.sifraZaposleni + "']"
        if (destinationRow.length > 0){
            let _sourceTd = sourceRow.find("td");
            let _destTd = destinationRow.find("td");
            
            _destTd.eq(4).replaceWith(_sourceTd.eq(4));
            _destTd.eq(5).replaceWith(_sourceTd.eq(5));
            _destTd.eq(6).replaceWith(_sourceTd.eq(6));
            _destTd.eq(7).replaceWith(_sourceTd.eq(7));
            _destTd.eq(8).replaceWith(_sourceTd.eq(8));
            _destTd.eq(9).replaceWith(_sourceTd.eq(9));
        }
    }

    afterRefreshTodayData(): void {
        global.ConsoleLogDegug("afterRefreshTodayData");
    }

    onCloseRegUraDanView():void{
        global.ConsoleLogDegug("SaopRegAttendanceView.onCloseRegUraDanView");    
        if (isUnDefined(this._lastDataValues) == false) {
            let detailRowDivId = this._rowDetailsDivPrefixId + this._lastDataValues.sifraZaposleni;
            this.refreshEmployeeRow(this._lastDataValues.leto,this._lastDataValues.mesec,this._lastDataValues.sifraZaposleni);
            this.getDetailsSubTable(detailRowDivId,this._lastDataValues.leto,this._lastDataValues.mesec,this._lastDataValues.sifraZaposleni);
            //
            this.refreshEmployeeMonthData(this._lastDataValues);
        }        
    } 
    
    public buttonsBinding():void{
        global.ConsoleLogDegug("buttonsBinding...");
        //
        let _this = this;
        let _btnAddEvents = $("#attendanceAllEmployeesForm").find("#btnAddEvents");
        if (_btnAddEvents.length > 0) {
            _btnAddEvents.off("click");
            _btnAddEvents.on("click", function () {
                global.ConsoleLogDegug("btnAddEvents.click...");
                let dataValues = _this.getDataValues(null, $(_this._obdobjeId));
                let _leto = dataValues.leto;
                let _mesec = ("0"+dataValues.mesec).slice(-2);
                let _dateStart = convertString2Date("01."+_mesec+"."+_leto,"dd.mm.yyyy");   
                //
                let _today = new Date();
                let __todayYear = _today.getFullYear();
                let __todayMonth = _today.getMonth();
                if (__todayYear == _dateStart.getFullYear() && __todayMonth == _dateStart.getMonth() ){
                    let _dan = ("0"+_today.getDate()).slice(-2);
                    _dateStart = convertString2Date(_dan+"."+_mesec+"."+_leto,"dd.mm.yyyy");   
                }
                //             
                _this._regUraDogodek.addRecord({ SelectedDay:formatDate(_dateStart,"dd.mm.yyyy"), sifraZaposleni:"*"});                 
            });
        } else {
            console.log("error:attendanceAllEmployeesForm.btnAddEvents not found...");
          
        }
        //
        let _btnDeleteEvents = $("#attendanceAllEmployeesForm").find("#btnDeleteEvents");
        if (_btnDeleteEvents.length > 0) {
            _btnDeleteEvents.off("click");
            _btnDeleteEvents.on("click", function () {
                global.ConsoleLogDegug("btnDeleteEvents.click...");
                let dataValues = _this.getDataValues(null, $(_this._obdobjeId));
                let _leto = dataValues.leto;
                let _mesec = ("0"+dataValues.mesec).slice(-2);
                let _dateStart = convertString2Date("01."+_mesec+"."+_leto,"dd.mm.yyyy");
                let _dateEnd = convertString2Date("01."+_mesec+"."+_leto,"dd.mm.yyyy");; 
                _dateEnd = new Date(_dateEnd.setMonth(_dateEnd.getMonth() + 1)-1);
                //
                _this._regUraDogodekDeleteView.showForm({DateStart:formatDate(_dateStart,"dd.mm.yyyy"),DateEnd:formatDate(_dateEnd,"dd.mm.yyyy")});
            });
        } else {
            console.log("error:attendanceAllEmployeesForm.btnAddEvents not found...");
          
        }    
        //      
        let _btnRefresh = $("#attendanceAllEmployeesForm").find("#btnRefresh");
        if (_btnRefresh.length > 0) {
            _btnRefresh.off("click");
            _btnRefresh.on("click", function () {
                global.ConsoleLogDegug("btnRefresh.click...");
                _this.refreshDataWithCurrentFiler();                
            });
        } else {
            console.log("error:attendanceAllEmployeesForm.btnRefresh not found...");
        }  
        //
        let _btnCheckInAll = $("#attendanceAllEmployeesForm").find(".btnCheckInAll");
        if (_btnCheckInAll.length > 0) {
            _btnCheckInAll.off("click");
            _btnCheckInAll.on("click", function () {
                global.ConsoleLogDegug("CheckInAll.click...");
                _this.attendanceAllEmployeesFormCheckInOutAll(true);
            });
        } else {
            console.log("error:attendanceAllEmployeesForm.btnCheckInAll not found...");
        }  
        //
        let _btnCheckOutAll = $("#attendanceAllEmployeesForm").find(".btnCheckOutAll");
        if (_btnCheckOutAll.length > 0) {
            _btnCheckOutAll.off("click");
            _btnCheckOutAll.on("click", function () {
                global.ConsoleLogDegug("btnCheckOutAll.click...");
                _this.attendanceAllEmployeesFormCheckInOutAll(false);
            });
        } else {
            console.log("error:attendanceAllEmployeesForm.btnCheckOutAll not found...");
        }          
                  
    }

    public setTableHeight():void {
        global.ConsoleLogDegug("setTableHeight...");

        let _height = $('#grid-toolbar-processed').height();
        let remaining_height = window.innerHeight - _height - 245;
        $('#attendanceAllEmployeesTable_wrapper').css("height",remaining_height);        
    }

    public setEventBindings():void{
        global.ConsoleLogDegug("setEventBindings...");
        let _this = this;

        _this.setTableHeight();
        window.onresize = function () {
            _this.setTableHeight();
        };

    }

    public refreshDataWithCurrentFiler(): void {
        global.ConsoleLogDegug("refreshDataWithCurrentFiler...");
        let dataValues = this.getDataValues(null, $(this._obdobjeId));
        this.refreshAllEmployeesTable(dataValues.leto, dataValues.mesec);
    }

    public afterAddEventsSuccess(result: any): void {
        global.ConsoleLogDegug("afterAddEventsSuccess...");
        this.refreshDataWithCurrentFiler();
    }    
    
    public afterDeleteEventsSuccess(result: any): void {
        global.ConsoleLogDegug("afterDeleteEventsSuccess...");
        this.refreshDataWithCurrentFiler();
    } 

    public onDeleteValidationError(result: any): void {
        global.ConsoleLogDegug("onDeleteValidationError...");
        this._regUraDogodekDeleteView.afterViewShowSuccess(result);
    }     

    public attendanceAllEmployeesFormCheckInOut(row:any,checkIn:boolean):void{
        global.ConsoleLogDegug("attendanceAllEmployeesFormCheckIn.click");
        let _this = this;
        //
        let _subMenu = row.currentTarget;   
        let _leto = _subMenu.getAttribute("data-year");
        let _mesec = _subMenu.getAttribute("data-month");
        let _sifraZaposleni = _subMenu.getAttribute("data-employeeCode");   
        //
        let _data = { leto: _leto, mesec: _mesec, sifraZaposleni: _sifraZaposleni };
        //
        let _urlAction = "";
        _urlAction = checkIn ? "REGAttendanceAllEmployees/CalculationCheckIn" :"REGAttendanceAllEmployees/CalculationCheckOut";        
        //
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBanner").html(response.viewContent);
            }

            _this.refreshEmployeeRow(parseInt(_leto),parseInt(_mesec),_sifraZaposleni);

        };
        _saopServicesCore.ExecuteAction({
            action: _urlAction
            , data: _data
            , type: "POST"
            , spinnerDivId: "#attendanceAllEmployeesLoading"
        });                    
    }

    public attendanceAllEmployeesFormCheckInOutAll(checkIn:boolean):void{
        global.ConsoleLogDegug("attendanceAllEmployeesFormCheckInOutAll.click");
        let _this = this;
        let dataValues = this.getDataValues(null, $(this._obdobjeId));
        //
        let _data = { leto: dataValues.leto, mesec: dataValues.mesec, sifraZaposleni: "" };
        //        
        let _urlAction = "";
        _urlAction = checkIn ? "REGAttendanceAllEmployees/CalculationCheckIn" :"REGAttendanceAllEmployees/CalculationCheckOut";        
        //
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function (response) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBanner").html(response.viewContent);
            }

            _this.refreshDataWithCurrentFiler();

        };
        _saopServicesCore.ExecuteAction({
            action: _urlAction
            , data: _data
            , type: "POST"
            , spinnerDivId: "#attendanceAllEmployeesLoading"
        });         
    }

    filterZaposleniTableRows(filterZaposleni: string) {
        // Declare variables
        var table, tr, _rowId, i, txtValue;
        table = document.getElementById("attendanceAllEmployeesTable");
        tr = table.getElementsByTagName("tr");
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            _rowId = null;
            let _trId = $(tr[i]).attr("data-sifra-zaposleni");
            if (!isUnDefined(_trId)) {
                _rowId = _trId.toString()
            }

            if (_rowId) {
                txtValue = _rowId.toUpperCase();
                if (isEmpty(filterZaposleni)) {
                    $(tr[i]).addClass("element-hidden");
                } else if (filterZaposleni.toUpperCase().indexOf(txtValue) > -1) {
                    $(tr[i]).removeClass("element-hidden");
                } else {
                    $(tr[i]).addClass("element-hidden");
                }
            }
        }
    }

    //
}


