﻿import { filterTableRows, global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopFloatingLabel } from '../Components/saop-floating-label';

export class DashboardEmployeePresenceView {
    private _libName:string;
    private _saopServicesCore: SaopServicesCore;

    private _saopFloatingLabel: SaopFloatingLabel = new SaopFloatingLabel();

    private divId: string = "#card8";

    private _searchValueId: string = "#EmployeePresenceSearchValue";
    private _searchValue: JQuery<HTMLElement>;

    constructor(libName: string = "") {
        this._libName = libName;
        //
        this._saopServicesCore = new SaopServicesCore();
    }

    refreshGridData(): void {
        global.ConsoleLogDegug("DashboardEmployeePresenceView.refreshGridData");

        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            $("#card8 .card-body").html(response);
            _this._saopFloatingLabel.init();
            _this.initFilterControls();
        };
        this._saopServicesCore.ExecuteAction({
            action:"Dashboard/EmployeePresencePartial"
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingcard8"
        });
    }  

    initFilterControls(): void {
        global.ConsoleLogDegug("DashboardEmployeePresenceView.initFilterControls");

        this._searchValue = $(this.divId).find(this._searchValueId);
        let _this = this;

        this._searchValue.off("keyup");
        this._searchValue.on("keyup", function () {
            _this.executeFilterSearch($(this).val().toString());
        });
    }


    executeFilterSearch(searchVal: string): void {
        global.ConsoleLogDegug("DashboardEmployeePresenceView.executeFilterSearch");

        filterTableRows(searchVal, this.divId);
    }
    //
}


