﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopRightSidebar,SaopRightSidebarView } from '../Components/saop-right-sidebar';
import { SaopRegKeZapDopustiView } from "./saop-reg-keZapDopusti-view";

export class SaopRegAbsenceEventsView extends SaopRegKeZapDopustiView {
    private _saopServicesCore: SaopServicesCore;

    public rightSidebar: SaopRightSidebar;

    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);
        this.refreshData = this.refreshAllData.bind(this);
        //
        this._saopServicesCore = new SaopServicesCore();
    }

    initView(): void {
        global.ConsoleLogDegug("SaopRegAbsenceEventsView.initView");
        this.initRightSidebar();
    }

    initRightSidebar() {
        this.rightSidebar = new SaopRightSidebar();
        this.rightSidebar.bindCarouselDiv("#right-sidebar-dopust", SaopRightSidebarView.Absence);
        this.rightSidebar.refreshOdsotnostiSummary("#dopustZaKoriscenjeSummary", "#ureZaKoriscenjeSummary");
    }

    bindGridEvents(){
        global.ConsoleLogDegug("SaopRegAbsenceEventsView.bindGridEvents");
        let _this = this;
        //
        $("#addKEZapDopusti").off("click");
        $("#addKEZapDopusti").on('click', function(row){
            global.ConsoleLogDegug("SaopRegAbsenceEventsView.addKEZapDopusti.click");
            _this.addRecord({});
        });         
        //
        $(".deleteKEZapDopusti").off("click");
        $(".deleteKEZapDopusti").on('click', function(row){
            global.ConsoleLogDegug("SaopRegAbsenceEventsView.deleteKEZapDopusti.click");
            let leto = row.target.getAttribute("data-leto");
            let zaporednastevilka = row.target.getAttribute("data-zaporednastevilka");
            //
            let deleteModalFormTitle = _this.translationService.translate("Brisanje");
            let deleteModalFormBody = _this.translationService.translate("Ali ste prepričani, da želite izbrisati odsotnost?");
            _this.deleteRecord(deleteModalFormTitle,deleteModalFormBody,{ leto: leto, zaporednaStevilka: zaporednastevilka });
        });         
    }

    refreshAllData(trigger: boolean = true): void {
        global.ConsoleLogDegug("SaopRegAbsenceEventsView.refreshAllData");
        let _this = this;

        this._saopServicesCore.afterDone = function(response){
            $("#regAbsenceForm").html(response);
            _this.bindGridEvents();
        };
        this._saopServicesCore.ExecuteAction({
            action:"REGAbsence/MyAbsencesPartial"
            ,type:"GET"
            ,spinnerDivId:"#saopLoading"
        }); 

        this.initRightSidebar();

        if (trigger && this.afterRefreshAllData != null) {
            this.afterRefreshAllData();
        }
    }

    afterRefreshAllData(): void {
        global.ConsoleLogDegug("SaopRegAbsenceCalendarEventsView.afterRefreshAllData");
    }
}


