﻿/// <reference path="../@types/data-tables/types.d.ts" />
import { SaopInputWFL,SaopInputWFLProperties } from './saop-input-wfl'
import { SaopselectWFL } from './saop-select-wfl'

export class SaopWebGridFilter {
  public id:string = "wgf1";
  public modalFilterArray: any = [];
  //This function initializes the content inside the filter modal
  configFilter(libName:string,htmlTable: any, colArray: any) {
    let _this = this;
    setTimeout(function () {
      var tableName = htmlTable[0].id;
      var columns = htmlTable.api().columns();
      $.each(colArray, function (i, arg) {
        let _colNo = arg.toString();
        $('#' + tableName + ' th:eq(' + _colNo + ')').attr("id","column-header-"+ _colNo +"");
        $('#' + tableName + ' th:eq(' + _colNo + ')').append('<span id="column-header-'+ _colNo +'-filter" class="saop-img-filter si-xs" onclick="'+libName+'.webGridFilter.showFilter(event,\'' + tableName + '_' + _colNo + '\');"></span>');
        $('#' + tableName + ' th:eq(' + _colNo + ')').append("<span id='column-header-"+ _colNo +"-sort' class=''>&nbsp;&nbsp;&nbsp;</span>");
        $('#' + tableName + ' th:eq(' + _colNo + ')').append("<div id='column-header-"+ _colNo +"-sizer'>");
      });

      var template =
        '<div class="modalFilter">' +
        '<div class="modal-content">' +
        '{0}</div>' +
        '<div class="modal-footer">' +
        '<a href="#!" onclick="'+libName+'.webGridFilter.performFilter(this, {1}, \'{2}\');"  class=" btn right waves-effect waves-light">V redu</a>' +        
        '<a href="#!" onclick="'+libName+'.webGridFilter.clearFilter(this, {1}, \'{2}\');"  class=" btn left waves-effect waves-light">Zbriši</a>' +
        '<a href="#!" onclick="'+libName+'.webGridFilter.hideFilter();" data-dismiss="modal" class=" btn left waves-effect waves-light">Prekliči</a>' +
        '</div>' +
        '</div>';

      $.each(colArray, function (index: any, value: any) {
        columns.every(function (i: any) {
          if (value === i) {
            var column = this, 
            content = '';//'<input type="text" class="filterSearchText" onkeyup="filterValues(this)" /> <br/>';
            var columnName = $(this.header()).text().replace(/\s+/g, "_");
            var distinctArray: any[] = [];
            column.data().each(function (d: string, j: any) {
              if (distinctArray.indexOf(d) == -1) {
                var id = tableName + "_" + columnName + "_" + j; // onchange="formatValues(this,' + value + ');
                content += '<div><input type="checkbox" value="' + d + '"  id="' + id + '"/><label for="' + id + '"> ' + d + '</label></div>';
                distinctArray.push(d);
              }
            }); 
            var newTemplate = $(template.replace('{0}', content).replace('{1}', value).replace('{1}', value).replace('{2}', tableName).replace('{2}', tableName));
            $('body').append(newTemplate);
            _this.modalFilterArray[tableName + "_" + value] = newTemplate;
            content = '';
          }
        });
      });
    }, 50);

  }


  //User to show the filter modal
  showFilter(e: any, index: any) {
    $('.modalFilter').hide();
    $(this.modalFilterArray[index]).css({ left: 0, top: 0 });
    var th = $(e.target).parent();
    var pos = th.offset();
    let arrayItem = this.modalFilterArray[index];
    //$(this.modalFilterArray[index]).width(th.width() * 0.75);
    $(arrayItem).css({ 'left': pos.left, 'top': pos.top, 'min-width':(th.width() * 0.75) });
    $(arrayItem).show();
    e.stopPropagation();
  }

  updateTableHeaderStatus(tableId:string,columnId:string,activeFilter:boolean){
    let _colFilterId = columnId+"-filter";
    let _colFilter = $(tableId).find(_colFilterId)[0];
    let _colFilterAtr = _colFilter.getAttribute("class");
    if(activeFilter){
        _colFilterAtr.replace("saop-img-filter", "saop-img-filter saop-cl-blue");
    } else {
        _colFilterAtr.replace("saop-img-filter saop-cl-blue", "saop-img-filter");
   
    }
  }

  //This function is to use the searchbox to filter the checkbox
  filterValues(node: any) {
    var searchString = $(node).val().toString().toUpperCase().trim();
    var rootNode = $(node).parent();
    if (searchString == '') {
      rootNode.find('div').show();
    } else {
      rootNode.find("div").hide();
      rootNode.find("div:contains('" + searchString + "')").show();
    }
  }

  //Execute the filter on the table for a given column
  performFilter(node: any, i: any, tableId: any) {
    var rootNode = $(node).parent().parent();
    var searchString = '', counter = 0;

    rootNode.find('input:checkbox').each(function (index: any, checkbox: any) {
      if (checkbox.checked) {
        searchString += (counter == 0) ? checkbox.value : '|' + checkbox.value;
        counter++;
      }
    });

    let _colId = "#column-header-"+i.toString();
    if(counter > 0){
      this.updateTableHeaderStatus('#' +tableId,_colId,true);
    } else {
      this.updateTableHeaderStatus('#' +tableId,_colId,false);
    }

    $('#' + tableId).DataTable().column(i).search(
      searchString,
      true, false
    ).draw();
    rootNode.hide();
  }

  //Removes the filter from the table for a given column
  clearFilter(node: any, i: any, tableId: any) {
    var rootNode = $(node).parent().parent();
    rootNode.find(".filterSearchText").val('');
    rootNode.find('input:checkbox').each(function (index: any, checkbox: any) {
      checkbox.checked = false;
      $(checkbox).parent().show();
    });

    let _colId = "#column-header-"+i.toString();
    this.updateTableHeaderStatus('#' +tableId,_colId,false);

    $('#' + tableId).DataTable().column(i).search(
      '',
      true, false
    ).draw();
    rootNode.hide();
  }

  hideFilter(node: any, i: any, tableId: any) {
    $('.modalFilter').hide();
  }

  setResizeEvent(gridId:string){
    let _this = this;
    var _tableDiv = $("table"+gridId+" th") as any;
    var thHeight = $("table"+gridId+" th:first").height();
	_tableDiv.resizable({
      handles: "e",
	  minHeight: thHeight,
      maxHeight: thHeight,
      minWidth: 40,
      start: function (event: any, ui: any) {
        console.log("resize start");
      },
      stop: function (event: any, ui: any) {
        console.log("resize stop");
      },
	  resize: function (event:any, ui:any) {
        console.log("resize...");
        var sizerid = "#" + $(event.target).attr("id") + "-sizer";
        $(sizerid).width(ui.size.width);
	  }
	});    

    setTimeout(function () {
      _this.setTableColumnsMinColumnsWidth(gridId);
    }, 1000);
  }

  public setTableColumnsMinColumnsWidth(gridId: string): void {
    let colsWidth = this.getTableColumnsWidth(gridId);

    for (var i = 0; i < colsWidth.length; i++) {
      let ind = i + 1;
      let _col = $(gridId + " tr th:nth-child(" + ind + ")");
      let _setW = !_col.hasClass("no-sort");
      //if (_setW){
        $(gridId + " tr th:nth-child(" + ind + ")").css({ "min-width": colsWidth[i] + "px" });
        $(gridId + " tr td:nth-child(" + ind + ")").css({ "min-width": colsWidth[i] + "px" });
      //}
    }
  }

  private getTableColumnsWidth(gridId: string): number[] {
    let colWidth: number[] = [];
    $(gridId + ' tr:nth-child(1) > td').each(function () {
      colWidth.push($(this).outerWidth());
    });

    // preverimo, če je kaj zapisov. Ce ni, moramo vzeti vrstico z imeni stolpcev
    if (colWidth.length <= 1) {
      colWidth = [];

      $(gridId + ' tr:nth-child(1) > th').each(function () {
        colWidth.push($(this).outerWidth());
      });
    }

    return colWidth
  }

  setSortElementEvent(gridId: string):void{
    let _this = this;
    $(gridId).on('click', 'th', function () {
      _this.setSortElement(gridId);
    });
  }
  
  setSortElement(gridId: string):void {
      $("" + gridId + " thead:first th").each(function (i, th) {
        let _col = $(th);
        let _colId = "#"+_col.attr('id');
        //let _sortType = _col.attr("column-sort");
        let _colIconIdUp = _colId+"-icon-up";
        let _colIconId = _colId+"-icon";
        let _colIconUpElement = $(gridId+' '+_colIconIdUp);
        let _colIconElement = $(gridId+' '+_colIconId);
        let cls = $(th).attr('class');

        //if (_sortType != null) {
          if (cls != null && cls.indexOf("no-sort") >= 0) {
            _colIconUpElement.removeClass("saop-img-arrow-down");
            _colIconElement.removeClass("saop-img-arrow-up");
            _colIconElement.removeClass("saop-img-arrow-down");         
            _colIconElement.removeClass("saop-cl-blue");             
          }          
          else if (cls != null && cls.indexOf("sorting_asc") >= 0) {
            _colIconUpElement.removeClass("saop-img-arrow-down");
            _colIconElement.removeClass("saop-img-arrow-down");
            _colIconElement.addClass("saop-img-arrow-up saop-cl-blue");
          } else if (cls != null && cls.indexOf("sorting_desc") >= 0) {
            _colIconUpElement.removeClass("saop-img-arrow-down");
            _colIconElement.removeClass("saop-img-arrow-up");
            _colIconElement.addClass("saop-img-arrow-down saop-cl-blue");            
          } else {
            _colIconElement.removeClass("saop-img-arrow-up");
            _colIconElement.removeClass("saop-img-arrow-down");         
            _colIconElement.removeClass("saop-cl-blue");   
            _colIconElement.addClass("saop-img-arrow-up");
            _colIconUpElement.addClass("saop-img-arrow-down");
          }
        //}
      });
  } 

  configSimpleFilter(dataTable: any):void {
    let _this = this;
    let tableId = "#"+dataTable[0].id;
    let _iCount = 0;
    var _filterEnable = false;
    let _th_columns = $(tableId).find('th');
    _th_columns.each( 
      function () {
        _iCount = _iCount + 1;
        let _element = $(this);
        let _filterType = _element.attr("column-filter");   
        let _nosort = _element.hasClass("no-sort");
        let _noResize = _element.hasClass("no-resize");
        let _actionColumn = _element.hasClass("saop-table-th-last");
        let _dt_checkboxes_cell = _element.hasClass("dt-checkboxes-cell");
        _element.attr("id","column-header-"+ _iCount +"");
        _element.css("outline","none");
        let col_title = _element.text();
        //let col_max_width = col_title.length * 7;
        if (_dt_checkboxes_cell != null && _dt_checkboxes_cell == true) {
          return;
        }
        _element.text("");

        //_element.append("<div class='saop-table-header-caption saop-table-text-truncate' id='column-header-"+ _iCount +"-caption' style='max-width:"+col_max_width.toString()+"px !important;'>"+col_title+"</div>");
        _element.append("<div class='saop-table-header-caption saop-table-text-truncate' id='column-header-"+ _iCount +"-caption'>"+col_title+"</div>");
        
        if (_filterType != null && _filterType.toLowerCase() == "text"){
          _filterEnable = true;
        } else if (_filterType != null && _filterType.toLowerCase() == "dropdown"){
          _filterEnable = true;
        }

        if (_actionColumn != null && _actionColumn == true){
          _nosort = true;
        }

        if (_nosort == null ||_nosort == false){
          _element.append("<div id='column-header-"+ _iCount +"-icon' style='float: right;height: 20px;width: 20px;display: inline-flex;align-items: center;'>");
          _element.append("<div id='column-header-"+ _iCount +"-icon-up' style='float: right;height: 20px;width: 5px;display: inline-flex;align-items: center;'>");        
        }

        if (_noResize == null ||_noResize == false){
          _element.append("<div id='column-header-"+ _iCount +"-sizer'>");
        }
        
      }
    );

    if (_filterEnable) {
      $(tableId+' thead tr')
      .clone(true)
      .addClass('filters')
      .appendTo(tableId+' thead');

      // Setup - add a text input to each footer cell
      let _filters = $(tableId).find('.filters');
      let _filters_th = _filters.find('th');
      _iCount = 0;
      _filters_th.each( 
        function () {
          _iCount = _iCount + 1;
          let _element = $(this);
          _element.off();

          _element.html("");

          let _filterType = _element.attr("column-filter");

          while(_element[0].attributes.length > 0)
          _element[0].removeAttribute(_element[0].attributes[0].name);

          let _colId = "col_"+_iCount.toString();        
          _element.attr("id",_colId);
          let _inputId = _colId+"_filter";        
          let title = _element.text();
          
          if (_filterType != null && _filterType.toLowerCase() == "text"){
            let _inputE = new SaopInputWFL({id:_inputId,caption:title,placeholder:title}).getInnerHtml();
            _element.html(_inputE);
          } else if (_filterType != null && _filterType.toLowerCase() == "dropdown"){
            let _selectId = _colId+"_select";   
            let selectHtml = new SaopselectWFL({id:_selectId,caption:"",placeholder:""}).getInnerHtml();
            let select = $(selectHtml);            
            //

            let _index = _iCount;
            _index = _index - 1;

            dataTable.DataTable()
            .column(_index+':visible')
            .data()
            .unique()
            .sort()
            .each(function (d:any, j:any) {
              if (d.length > 0){
                let _content = d;
                let _firstChar = _content[0];
                if (_firstChar.charCodeAt(0) > 1000) {_content = _content.replace(_firstChar+" ","");}
                select.find('select').last().append('<option value="' + d + '">' + _content + '</option>');
              }
            });

            _element.append(select);
          }    
        } 
      );

      // Apply the filter
      $(tableId+" thead input").on( 'keyup change', function () {
        const target = event.target as HTMLInputElement;
        let _index = $(this).parent().parent().index();
        dataTable.DataTable()
            .column(_index+':visible')
            .search( target.value )
            .draw();

        //var val = $.fn.dataTable.util.escapeRegex(target.value);
        //dataTable.DataTable().search(val ? '^' + val + '$' : '', true, false).draw();

      } );    
 
      // Apply the dropdown filter
      $(tableId+" thead select").on( 'change', function () {
        const target = event.target as HTMLInputElement;
        let _index = $(this).parent().parent().index();
        var val = $.fn.dataTable.util.escapeRegex(target.value);
        dataTable.DataTable()
            .column(_index+':visible')
            .search(val ? '^' + val + '$' : '', true, false)
            .draw();
      } );  

      //
    }


  }

  configSimpleSort(gridId: string):void {
    this.setSortElement(gridId);
    this.setSortElementEvent(gridId);
  }

  //
}






