﻿import { SaopWebGrid,SaopWebGridProperties,SaopWebGridToolbar,SaopWebDataTable} from "../Components/saop-grid";
import { SaopServicesCore } from "../Core/saop-services-core";
import { global, isEmpty, isUnDefined, filterTableRows, convertString2Date, formatDate } from "../Common/saop-common";

export class OPNPotniNalogiAllGrid {
  private wgId:string;
  private libName:string;
  private _saopServicesCore: SaopServicesCore;
  //
  public webGrid:SaopWebGrid;

  constructor(libName: string) {
    this.wgId = "#OPNPotniNalogiGridAll";
    this.libName = libName;
    this._saopServicesCore = new SaopServicesCore();
  }  

  initView(){
    this.initWebGrid();
    this.initWebGridListners();  
  }

  initWebGrid(){
    
    let captionToolbar = $(this.wgId).attr("caption-toolbar")!;
    //
    this.webGrid = new SaopWebGrid(this.libName+".webGrid");
    let gridProperties: SaopWebGridProperties;
    let gridToolbar:SaopWebGridToolbar;
    let dataTable:SaopWebDataTable;
    let toolbarItemsTemplateId = $(this.wgId).attr("toolbar-items-templateId");
    //
    gridToolbar = {id:"saopWebGridTB1",caption:captionToolbar, itemsTemplateId:toolbarItemsTemplateId};

    dataTable = { enabled:true,columnDefsSettings:[{orderable: true, targets:  "no-sort"}]};

    gridProperties = {
        gridId:this.wgId
        ,togglePanel:{enabled:false}
        ,toolbar:gridToolbar
        ,dataTable:dataTable
      };        
    //
    this.webGrid.init(gridProperties);        
  }

  initWebGridListners(){
    let _this = this;
    //
    let _SearchValue = $('#collapsesaopWebGridTB1').find('#SearchValue');  
    if (_SearchValue.length > 0) {
      _SearchValue.off("change");
      _SearchValue.on("change",_this.onSearchValue2Change.bind(_this));
    }
    //
    $("body").off("click", this.wgId + " td.td-action-details");
    $("body").on("click", this.wgId + " td.td-action-details", function () {
        let selectedRow = $(this).closest("tr");
        _this.showHideDetailsSubTable(selectedRow);
    });    
     
  }

  showHideDetailsSubTable(selectedRow: JQuery<HTMLTableRowElement>): void {
    global.ConsoleLogDegug("SaopRegAttendanceAllEmployeesView.showHideDetailsSubTable");
    let data_leto = selectedRow.attr("data-leto");
    let data_stevilka = selectedRow.attr("data-stevilka");
    let rowDetailsId = "#detailRow"+data_leto+data_stevilka;
    //
    let rowDetailsIdElement = $(rowDetailsId);
    // prikazi/skrij bottom border
    selectedRow.toggleClass("saop-table-row-border-bottom-none")

    // prikazi/skrij details tabelo
    rowDetailsIdElement.toggleClass("saop-table-row-hidden");
    rowDetailsIdElement.toggleClass("saop-table-row-showed");    
    // // sprememba ikone
    let iconElement = selectedRow.find("span");
    iconElement.toggleClass("saop-img-right");
    iconElement.toggleClass("saop-img-down");    

  }

  refreshData(htmlContent:string){
    this.webGrid.replaceGridRows(htmlContent);
    this.initWebGridListners();
  }

  refreshGridData():void {
    let _this = this;

    let _SearchValue = $('#collapsesaopWebGridTB1').find('#SearchValue');
    let _letoPotnegaNaloga = "";
    if (_SearchValue.length > 0) {
      _letoPotnegaNaloga = _SearchValue.val() as string;
    }

    this._saopServicesCore.afterDone = function(response){
      _this.refreshData(response);
    };
    this._saopServicesCore.ExecuteAction({
        action:"TravelOrder/IndexAll" 
        ,data: {letoPotnegaNaloga : _letoPotnegaNaloga}
        ,type:"GET"
    });     
  }

  onSearchValue2Change(e:any):void{
    this.refreshGridData();
  }  

 
  //
}






