﻿import { SaopViewForm } from "../core/saop-view-form";
import { SaopServicesCore } from "../Core/saop-services-core";
import { global } from "../Common/saop-common";
import { SaopTranslationService } from '../Core/saop-translate';
import { SaopRightSidebar, SaopRightSidebarView } from '../Components/saop-right-sidebar';

export class SaopEmployeeLeaveCalendar extends SaopViewForm {
    private _saopServicesCore: SaopServicesCore;
    private _yearCaption: string = "yearCaption";
    private _btnPreviousId: string = "btnYearPrevious";
    private _btnNextId: string = "btnYearNext";
    public _sifraZaposleni:  string = "";
    public _nazivZaposleni: string = "";
    public rightSidebar: SaopRightSidebar;

    private _translationService: SaopTranslationService;
    //
    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);

        this._translationService = new SaopTranslationService();
        this.setTerminologyList();

        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "AbsenceConfirmation/EmployeeAbsencesCalendarPartial";
        this.deleteRecordEndpoint = "";
        this.fullScreen = true;

        this._saopServicesCore = new SaopServicesCore();

        this.afterViewShowSuccess = this.initView;
        this.afterSubmitSuccess = this.initView;

    }

    initRightSidebar() {
        this.rightSidebar = new SaopRightSidebar();
        this.rightSidebar.bindCarouselDiv("#right-sidebar-ajax", SaopRightSidebarView.Absence);
        this.rightSidebar.refreshOdsotnostiEmployeeSummary(this._sifraZaposleni, "#dopustZaKoriscenjeSummary", "#ureZaKoriscenjeSummary");
    }

    setTerminologyList() {
        let dictionary: string[] = ["Pregled odsotnosti"];
        this._translationService.setTranslationsList(dictionary);
    }   

    initView(): void {
        this.initRightSidebar();
        this.LoadViewEvents();
    }

    public LoadViewEvents(): void {
        global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.bindGridEvents");
        let _this = this;

        _this.fullTitle = _this._translationService.translate("Pregled odsotnosti") + ":  " + _this._sifraZaposleni + " - " + _this._nazivZaposleni;

        $("#" + this._btnPreviousId).off("click");
        $("#" + this._btnPreviousId).click(function () {
            global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.btnPrevious.click");
            let leto = _this.getCurrentCalendarYear() - 1;
            _this.refreshCalendarAllMonthsData(leto);
        });

        $("#" + this._btnNextId).off("click");
        $("#" + this._btnNextId).click(function () {
            global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.btnNext.click");
            let leto = _this.getCurrentCalendarYear() + 1;
            _this.refreshCalendarAllMonthsData(leto);
        });

        $('[rel="tooltipCalendarDayDetails"]').popover({
            content: function () {
                global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.tooltipCalendarDayDetails.popover");
                var content = $(this).attr("data-popover-content");
                return $(content).children(".popover-body").html();
            }
        });
    }

    refreshCalendarAllMonthsData(leto: number): void {
        global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.refreshCalendarAllMonthsData");
        let _this = this;

        this._saopServicesCore.afterDone = function (response) {
            $("#regEmployeeAbsenceCalendar").html(response);
            _this.LoadViewEvents();
        };
        this._saopServicesCore.ExecuteAction({
            action: "AbsenceConfirmation/EmployeeAbsencesYearCalendarPartial"
            , type: "GET"
            , data: { leto: leto, sifraZaposleni: this._sifraZaposleni }
            , spinnerDivId: "#saopLoading"
        });
    }

    getCurrentCalendarYear(): number {
        global.ConsoleLogDegug("SaopEmployeeLeaveCalendar.getCurrentCalendarYear");
        return $("#" + this._yearCaption).data("year");
    }

    public setFullTitle(): void {

        this.fullTitle = this._translationService.translate("Pregled odsotnosti") + ":  " + this._sifraZaposleni + " - " + this._nazivZaposleni;
    }
}


