﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global, getRelativeSiteURL, getSiteAbsoluteURL, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopViewForm, SaopWfAction } from "../Core/saop-view-form";
import { SaopUploadFiles } from "../Components/saop-upload-files";
import { SaopAttachmentList } from "../Components/saop-attachment-list";
import { OPNPotniNalogiGoggleMapsView } from "../OPN/OPNPotniNalogiGoggleMaps-view";
import { SaopMessageDlg, SaopMsgDlgButton } from '../Components/saop-message-dlg';
//import { SaopMultiselect } from "../Components/saop-multiselect";
import { SaopServicesCore } from "../Core/saop-services-core";
import { OPNSelectPassengersView } from "../opn/OPNSelectPassengers-view";
import { SaopSelectizeSelectWraper } from "../Components/saop-selectize-select-w";
import { SaopInputMask } from "../Components/saop-inputmask";

export class OPNPotniNalogiView extends SaopViewForm {
  private _formId:string;
  private _saopAttachmentList: SaopAttachmentList;
  private _opnPotniNalogiGoggleMapsView :OPNPotniNalogiGoggleMapsView;
  private _opnSelectPassengersView:OPNSelectPassengersView;
  private _saopSelectizeSelectWraper:SaopSelectizeSelectWraper;
  private _reloadAttachments:boolean = false;
  //
  public saopUploadFiles:SaopUploadFiles;  
  public _removeLinkDialog:SaopViewForm

  constructor(viewId:string,libName:string = ""){
    super(viewId,libName);
    //
    this._reloadAttachments = false;
    this.title = this.translationService.translate("Potni nalog");
    this.addRecordEndpoint = "TravelOrder/CreateTravelOrder";
    this.editRecordEndpoint = "TravelOrder/EditTravelOrder";
    this.deleteRecordEndpoint = "TravelOrder/DeleteTravelOrder"; 
    this.saopUploadFiles = new SaopUploadFiles(viewId,libName+".saopUploadFiles");  
    this._opnPotniNalogiGoggleMapsView = new OPNPotniNalogiGoggleMapsView("#opnPotniNalogiView003",libName);
    this._opnSelectPassengersView = new OPNSelectPassengersView("#opnPotniNalogiView004",libName+"._opnSelectPassengersView");
    this._opnSelectPassengersView.onBtnOkClick = this.onBtnOkClickEx.bind(this);
    this._saopSelectizeSelectWraper = new SaopSelectizeSelectWraper(viewId,libName);
    //
    //this._selectZaposleni = new SaopMultiselect(libName+"._selectZaposleni",{selectAll:false});
    //this._selectZaposleni.afterChangeCommonEvent = this.refreshSelectedEmployeesList.bind(this);
    //
    this.afterViewShowSuccess = this.initView;
    this.afterSubmitSuccess = this.reloadView;
    this._saopAttachmentList = new SaopAttachmentList("#travelOrderAttachments", libName + "._saopAttachmentList");
    this._saopAttachmentList.initComponent(
      {
          validationFingerprintController: "SPLDocument",
          validationFingerprintAction: "ValidateAttachmentFingerPrint",
          validationFingerprintBannerDivId: viewId + " #uploadFilesValidationsAjax",
          validationController: "SPLDocument",
          validationAction: "ValidateDownloadingDocumentAttachment",
          validationBannerDivId: viewId + " #uploadFilesValidationsAjax",
          downloadController: "SPLDocument",
          downloadAction: "DownloadDocumentAttachment",
          deletedAttachmentListId: viewId + " " + "#PriponkeBrisane"
      });

  }

  setDictionary(){
      let dictionary:string[] = ["Uredi: Potni nalog","Brisanje","Ali ste prepričani, da želite preklicati dogodek?","Ali ste prepričani, da želite izbrisati izbranega sopotnika?","Brisanje"];
      this.setTerminologyList(dictionary);
  } 
  
  public deleteRecord(deleteData:any):void{
    let deletTitle = this.translationService.translate("Brisanje");
    let deleteMessage = this.translationService.translate("Ali ste prepričani, da želite preklicati dogodek?");    
    super.deleteRecord(deletTitle,deleteMessage,deleteData);
  }  

  public initView():void{
    this._formId = $(this.id).find("form").attr("Id");

    if (this._formId.length == 0) {
      console.log("form id not found ...");
    }
    this._formId = "#" + this._formId; 
    //    
    this.LoadViewEvents();
    this._saopSelectizeSelectWraper.initAll(".selectizeSelect");
    this._opnPotniNalogiGoggleMapsView.prepareView(this.id,"Izračun relacije");
  }

  public reloadView():void{
    this.initView();
    this.copyFiles("#customFileBack","#customFile", true);
    this.setFocusOnError();
//    this.removeAllFiles("#customFileBack");
  }

  public LoadViewEvents ():void{
    let _viewDiv = $(this.id);
    if (_viewDiv.length == 0) {return;}

    let _this = this;
    //this._formId = "#detailsOPNPotniNalogiFullForm";

    this.saopUploadFiles.initComponent({controller:"TravelOrder",action:"ValidateFiles",validateAjax:true,formId:this._formId,validationDivId:"#uploadFilesValidationsAjax"});
    this.saopUploadFiles.afterRefreshFilesList = this.saopUploadFiles.ajaxValidate;

    $('#btnOk').off('click');
    $('#btnOk').bind('click', 
      function() {
        _this.copyFiles("#customFile","#customFileBack");
        let _result = _this.saopUploadFiles.ajaxValidate();
        return _result;        
      }          
    );    

    $('#PredvideniCasOdhoda').off('focusout',this.onFocusOutPredvideniCasOdhoda.bind(this));
    $("#PredvideniCasOdhoda").bind('focusout',this.onFocusOutPredvideniCasOdhoda.bind(this));

    $('#StanjeStevcaKonec').off('focusout',this.onFocusOutStanjeStevcaKonec.bind(this));
    $("#StanjeStevcaKonec").bind('focusout',this.onFocusOutStanjeStevcaKonec.bind(this));    

    $('#btnCheckIn').off('click');
    $('#btnCheckIn').bind('click', 
      function(e) {
        _this.ChechIn();    
      }          
    );  

    $('#btnCancel').off('click');
    $('#btnCancel').bind('click', 
      function(e) {
        _this.CancelTO();    
      }          
      ); 

      $('#btnSyncREG').off('click');
      $('#btnSyncREG').bind('click',
          function (e) {
              _this.SyncREG();
          }
      );  

    // $(this._formId).submit(function(e){
    //   let _sender = e.target;
    //   //$('#CheckIn').val('true');
    // });    
    
    $('#OdobrenaUporaba').off('change');
    $('#OdobrenaUporaba').on('change', _this.onChangeOdobrenaUporaba.bind(this)); 
    $('#OdobrenaUporaba').change();

    $('#SifraRelacije').off('change');
    $('#SifraRelacije').on('change', _this.onChangeSifraRelacije.bind(this)); 
    $('#SifraRelacije').change();

    $('#SifraNaloge').off('change');
    $('#SifraNaloge').on('change', _this.onChangeSifraNaloge.bind(this)); 
    $('#SifraNaloge').change();

    $('#btnOpnRoutes').off('click');
    $('#btnOpnRoutes').on('click', _this.onCliskbtnOpnRoutes.bind(this));     

    $('#btnAddPassenger').off('click');
    $('#btnAddPassenger').on('click', _this.onBtnAddPassengerClick.bind(this));   

    //this._selectZaposleni.init("#SelectedEmployees", "Zaposleni"); 

    this._saopAttachmentList.initWebGridListners();

    this.bindRemoveButtonEvents();

    //this.loadLinks();
  }

  // public loadLinks():void{
  //   // let _selectedEmployeesList = $(this._formId).find("#SelectedEmployeesList").val();
  //   // let _selectedEmployeesArray = _selectedEmployeesList.toString().split(';');
  //   let _selectedEmployeesList = $(this._formId).find("#SelectedEmployeesList");
  //   if(_selectedEmployeesList.length > 0) {
  //     let _selectedEmployeesArray = _selectedEmployeesList.val().toString().split(';');    
  //     this._selectZaposleni.setSelectValues(_selectedEmployeesArray);
  //   }
  // }

    public ChechIn(): void {
        this.checkInDialog();
    }

    public CancelTO(): void {
        $('#CancelTO').val('true');
    }

    public SyncREG(): void {
        global.ConsoleLogDegug("SyncREG");
        let _this = this;

        let _letoPotnegaNaloga = $("#LetoPotnegaNaloga").val();
        let _stevilkaPotnegaNaloga = $("#StevilkaPotnegaNaloga").val();

        let params = { letoPotnegaNaloga: _letoPotnegaNaloga, stevilkaPotnegaNaloga: _stevilkaPotnegaNaloga };

        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function (response) {
            $("#tocen_cas").html(response.viewContent);
            
            if (response.success) {
                alertify.message(response.alertMessage);
            }

            $('#btnSyncREG').off('click');
            $('#btnSyncREG').bind('click',
                function (e) {
                    _this.SyncREG();
                }
            );  

            let saopInputMask = new SaopInputMask();
            saopInputMask.init();
        };
        _saopServicesCore.ExecuteAction({
            action: "TravelOrder/SyncREG"
            , data: params
            , type: "POST"
            , spinnerDivId: "#saopLoading"
        });     
    }

  onFocusOutPredvideniCasOdhoda(e:any):void{
    if(!$("#PredvideniCasPrihoda").val()) {
      $("#PredvideniCasPrihoda").val($("#PredvideniCasOdhoda").val());
    }
  }

  onChangeOdobrenaUporaba(e:any):void{
    let _selectedValue = e.target.value;  
    let _registrskaStevilkaPredlogGroup = $('#RegistrskaStevilkaPredlogGroup');
    if (_selectedValue == '1') {
      this.suggerstVehicle();
      _registrskaStevilkaPredlogGroup.show();
    } else {
      _registrskaStevilkaPredlogGroup.hide();
    }
  }

  onChangeSifraRelacije(e:any):void {
    let _selectedValue = e.target.value;  
    let _privzetaSifraRelacije = $('#PrivzetaSifraRelacije').val();
    if (_selectedValue == _privzetaSifraRelacije) {
      this.saopFloatingLabel.setCaptionAsRequired("#lblDodatniOpisRelacije",true);
    } else {
      this.saopFloatingLabel.setCaptionAsRequired("#lblDodatniOpisRelacije",false);
      $("#DodatniOpisRelacije").focusout();      
    }
  }


  onChangeSifraNaloge(e:any):void{
    let _selectedValue = e.target.value;  
    let _privzetaSifraNaloge = $('#PrivzetaSifraNaloge').val();
    if (_selectedValue == _privzetaSifraNaloge) {
      this.saopFloatingLabel.setCaptionAsRequired("#lblDodatniOpisNaloge",true);
    } else {
      this.saopFloatingLabel.setCaptionAsRequired("#lblDodatniOpisNaloge",false);
      $("#DodatniOpisNaloge").focusout();
    }
  }

  onCliskbtnOpnRoutes(e:any):void{
    global.ConsoleLogDegug("onCliskbtnOpnRoutes...");
    this._opnPotniNalogiGoggleMapsView.showForm(this.id);
  }  

  onFocusOutStanjeStevcaKonec(e:any):void{
    let _StanjeStevcaZacetek = parseInt($("#StanjeStevcaZacetek").val() as string);
    let _StanjeStevcaKonec = parseInt($("#StanjeStevcaKonec").val() as string);
    let _DejanskiKm = _StanjeStevcaKonec -_StanjeStevcaZacetek;
    if(_DejanskiKm > 0) {
       $("#DejanskiKm").val(_DejanskiKm);
    }
  }

    public checkInDialog(): void {
        global.ConsoleLogDegug("checkInDialog");

        let REG_sync = $('#WebUsklajevanjeREG').val().toString().toLowerCase() == 'true';

        let message = this.translationService.translate("Ali res želite zaključiti? Z zaključkom naknadne spremembe na potnem nalogu ne bodo več možne.");
        if (REG_sync) {
            message = message + "<br><br>" + this.translationService.translate("Vnesen čas odhoda/prihoda se bo prenesel v Registracijo prisotnosti.")
        }

        let title = this.translationService.translate("Zaključevanje");
        let saopMessageDlg = new SaopMessageDlg({ data: null, parent: null })
        saopMessageDlg.onYes = this.SubmitAndChechIn.bind(this);
        saopMessageDlg.Show(message, title, 1, [SaopMsgDlgButton.mbYes, SaopMsgDlgButton.mbNo]);
    }

  public SubmitAndChechIn():void { 
    $('#CheckIn').val('true');
    $('#detailsOPNPotniNalogiFullForm').submit();
  }

  refreshLinksData(jsonEmployeesList:string):void {
    global.ConsoleLogDegug("refreshLinksData");
    let _this = this;

    let _data = this.getData();
    Object.assign(_data, {"jsonEmployeesList":jsonEmployeesList});

    let _saopServicesCore = new SaopServicesCore();
    _saopServicesCore.afterDone = function(response){
      $("#SelectedEmployeesListAjax").html(response);
      _this.bindRemoveButtonEvents();
    };
    _saopServicesCore.ExecuteAction({
        action:"TravelOrder/GetLinks" 
        ,data: _data
        ,type:"GET"
    });     
  }

  onBtnAddPassengerClick(sender:any):void {
    console.log("onBtnAddPassengerClick");
    let _data = this.getData();
    this._opnSelectPassengersView.showViewForm(SaopWfAction.wfaRecordDestails, _data);
  }

  public onBtnOkClickEx(sender: any): void {
    global.ConsoleLogDegug("onBtnOkClickEx");
    //
    this.refreshLinks(sender);
    //
    this._opnSelectPassengersView.CloseViewForm();
    //
  }  

  public refreshLinks(sender: any): void {
    global.ConsoleLogDegug("refreshLinks");
    //
    let _selectedEmployeesList = $(this._formId).find("#SelectedEmployeesList").val() as string;
    let _opnModel_SelectedEmployees = $(this._opnSelectPassengersView.id).find("#opnModel_SelectedEmployees").val() as [any];
    if (isUnDefined(_opnModel_SelectedEmployees) == false){
      let _opnModel_SelectedEmployeesDeliminated = _opnModel_SelectedEmployees.join(";");
      _selectedEmployeesList = _selectedEmployeesList +";"+ _opnModel_SelectedEmployeesDeliminated;
    }
    //
    let _selectedEmployeesListDistinct = [...new Set(_selectedEmployeesList.split(";"))];
    _selectedEmployeesList = _selectedEmployeesListDistinct.join(";");
    //
    $(this._formId).find("#SelectedEmployeesList").val(_selectedEmployeesList);
    let _selectedEmployeesListArray = _selectedEmployeesList.split(";");
    //
    var myJsonString = JSON.stringify(_selectedEmployeesListArray);
    this.refreshLinksData(myJsonString);
  }

  public removeLink(sifrazaposleni: string): void {
    global.ConsoleLogDegug("removeLink:"+sifrazaposleni);
    let _selectedEmployeesList = $(this._formId).find("#SelectedEmployeesList").val() as string;
 
    let _selectedEmployeesListDistinct = [...new Set(_selectedEmployeesList.split(";"))];
    //
    _selectedEmployeesListDistinct = _selectedEmployeesListDistinct.filter(function(item) {
      return item !== sifrazaposleni
    })
    //
    _selectedEmployeesList = _selectedEmployeesListDistinct.join(";");

    //_selectedEmployeesList = _selectedEmployeesList.replace(";"+sifrazaposleni,"");

    $(this._formId).find("#SelectedEmployeesList").val(_selectedEmployeesList);
    //
    this.refreshLinks(sifrazaposleni);
  }


  public bindRemoveButtonEvents ():void{
    let _this = this;
    //
    $(this.id).find('.saop-remove-item-event').off('click');
    $(this.id).find('.saop-remove-item-event').bind('click', 
      function(row) {
        global.ConsoleLogDegug(".saop-remove-item-event.click");
        //
        let _btnRemove = row.currentTarget;
        let _sifrazaposleni = _btnRemove.getAttribute("data-sifrazaposleni");
        //
        _this.showDeleteDialog(_sifrazaposleni);
        //
        $(_this._opnSelectPassengersView.id).find("#opnModel_SelectedEmployees").val("")
      }          
    );    
  }

  public showDeleteDialog(sifrazaposleni: string):void{
    global.ConsoleLogDegug("deleteDialog");
    let _this = this;
    let message = this.translationService.translate("Ali ste prepričani, da želite izbrisati izbranega sopotnika?");
    let title = this.translationService.translate("Brisanje");
    let saopMessageDlg = new SaopMessageDlg({data:null,parent:null})  
    saopMessageDlg.onYes = this.removeLink.bind(this,sifrazaposleni);
    saopMessageDlg.Show(message,title,1,[SaopMsgDlgButton.mbYes,SaopMsgDlgButton.mbNo]);
  }

  public suggerstVehicle():void {
    //
    var _registrskaStevilkaOsebnaPredlog = $(this.id).find("#RegistrskaStevilkaOsebnaPredlog");
    var _registrskaStevilkaPredlog = $(this.id).find("#RegistrskaStevilkaPredlog");
    if (_registrskaStevilkaOsebnaPredlog.length > 0 && _registrskaStevilkaPredlog.length > 0) {
      var _vaue = _registrskaStevilkaPredlog.val() as string;
      if (_vaue.length == 0) {
        _registrskaStevilkaPredlog.val(_registrskaStevilkaOsebnaPredlog.val());
      }
    }
  }

  public copyFiles(idFrom:string, idTo:string, refreshList :boolean = false):void{

    let inputElementFrom = <HTMLInputElement>$(idFrom)[0];
    let filesFrom = inputElementFrom.files;

    let inputElementTo = <HTMLInputElement>$(idTo)[0];
    inputElementTo.files = filesFrom;
    if (refreshList) {
      this.saopUploadFiles.refreshFileList(inputElementTo);
    }
  }

  public removeAllFiles(id:string):void{
    let inputElementFrom = <HTMLInputElement>$(id)[0];
    inputElementFrom.value = null;
  }

  public setFocusOnError():void{
    let _validationSummary = $("#detailsKePlanDopustovValidationSummary");
    if (_validationSummary.length > 0) {
      $("#detailsKePlanDopustovValidationSummary").find("li").focus();
    }
  }

    public onSubmitSuccessComplete(response: any): void {
        if (response.viewContent) {
            alertify.message(response.viewContent);
        }  
    }

  //
}






