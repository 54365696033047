﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopRightSidebar } from '../Components/saop-right-sidebar';
import { SaopRegTodayEventsView } from '../REG/saop-regTodayEvents-view'
import { ObdobjefilterValues } from "../Components/saop-select-month-year";


export class DashboardTodayEventsView {
    private _libName:string;
    private _saopServicesCore :SaopServicesCore;
    //
    public regTodayEventsView: SaopRegTodayEventsView;
    // 
    public addEnabled: boolean = false;

    constructor(libName: string = "") {
        this._libName = libName;
        //
        this._saopServicesCore = new SaopServicesCore();
        //
        this.regTodayEventsView = new SaopRegTodayEventsView("#regDogodekView0",this._libName+".regTodayEventsView");
        this.regTodayEventsView.initClock();
        this.regTodayEventsView.refreshData = this.refreshGridData.bind(this);
        this.regTodayEventsView.regCurrentEventView.afterSubmitSuccess = this.refreshGridData.bind(this);       
    }

    refreshGridData(): void {
        global.ConsoleLogDegug("DashboardCard1.refreshTodayEventData");
        let _this = this;

        this._saopServicesCore.afterDone = function(response){
            $("#card1 .card-body").html(response);
            _this.regTodayEventsView.regCurrentEventView.bindFormAjaxEvents();
            _this.regTodayEventsView.bindGridEvents(); 
            _this.enableAdd();
        };
        this._saopServicesCore.ExecuteAction({
            action:"Dashboard/TodayEventPartial"
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingcard1"
        });        

        let _saopRightSidebar: SaopRightSidebar = new SaopRightSidebar();
        let obdobjefilterValues: ObdobjefilterValues = new ObdobjefilterValues();
        _saopRightSidebar.refreshRegUraDogodkiSummary("#regUraDogodkiSummary", obdobjefilterValues,"#saopLoadingRegUraMonthEventsSidebarr");
    }    

    enableAdd(): void {
        var val = $("#RegUraDogodekBrowseViewModel_AddEnabled").val();
        var el = $("#regUraDogodekAddRecord");
        if (el.length) {
            el.prop("disabled", val.toString().toLowerCase()==="false");
        }
    }
    //
}


