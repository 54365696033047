﻿/// <reference path="../@types/saop-core/saopSelectizeSelect.d.ts" />

import { global, getRelativeSiteURL, getSiteAbsoluteURL, isEmpty, isUnDefined } from "../Common/saop-common";

export class SaopSelectizeSelectToolTip {
    constructor() {
        global.ConsoleLogDegug("SaopSelectizeSelectToolTip.constructor");
    }

    public InitTooltype(selectorName: string = "[data-selectable]"): void {
        $(document).off("mouseenter", selectorName, (e) => this.SetTooltype(e));
        $(document).on("mouseenter", selectorName, (e) => this.SetTooltype(e));
    }

    public SetTooltype(e: any): void {
        const element = $(e.currentTarget);
        element.tooltip({
            placement: 'top',
            title: element.find('span').text().trim(),
        }).tooltip('show');
    }
}





